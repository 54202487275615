<template>
  <div class="add-new-mission" style="background: #ECEFF0; font-size: 15px">
    <div class="col-12">
      <div class="form-group">
        <label for="comment">{{$t('mission.conclusionForMission')}}</label>
        <select class="form-control" id="sel1" name="sellist1" 
          v-model="missionData.summary" 
          @change="getDocumentForSummary(missionData.summary)">
          <option
            v-for="(userData, index) in conclusionList"
            :key="userData+index"
            :value="userData.id"
          >{{userData.name}}</option>
        </select>
      </div>
    </div>
    <div class="col-12 pb-2">
      <label>{{$t('mission.assignPersonCompany')}}</label>
      <VueMutiselect
        :optionData="optionData"
        :customLabel="customLabel"
        :labelText="labelTextValue"
        :trackByText="trackByTextValue"
        :placeHolder="$t('mission.selectPerson')"
        @select="getAssignerSelect"
      ></VueMutiselect>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label for="comment">{{$t('mission.missionTitle')}}</label>
        <textarea class="form-control" rows="4" id="comment" v-model="missionData.missionTitle"></textarea>
      </div>
    </div>
    <div class="col-12" v-if="isChairmanRole()">
      <div class="form-group" style="display: flex">
        <label for="" class="mr-4">{{$t('opinion.security')}}</label>
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            id="customCheck"
            name="example1"
            v-model="missionData.security"
          />
          <label class="custom-control-label" for="customCheck">{{$t('opinion.yes/no')}}</label>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label for="comment">{{$t('mission.discussInfo')}}</label>
        <textarea class="form-control" rows="3" id="comment" v-model="missionData.discussInfo"></textarea>
      </div>
    </div>
    <div class="col-12" style="display: flex">
      <div class="col-2 pl-0">
        <label>{{$t('mission.endTime')}}</label>
      </div>
      <div class="col-6 pl-0">
        <date-picker
          style="font-size: 14px; text-align: right;"
          v-model="missionData.endTime"
          :config="options"
        ></date-picker>
      </div>
    </div>
    <div class="col-12 pt-2 pb-2">
      <label>{{$t('mission.selectDocument')}}</label>
      <!-- list document -->
      <div
        class="pl-3 pr-2 pt-3 pb-3 scrollbar"
        style="border-radius: 5px; background: white"
      >
        <!--  -->
        <div v-for="(item, index) in documentList" :key="item + index" class>
          <div class="my-input pb-2" style="display: flex">
            <div class="item-center p-0 pr-1" style="text-align: center">
              <img :src="item.poster_url" style="width: 15px; height: 20px;" />
            </div>
            <div :class="DRAWER_CHANGE ? 'col-11 item-center p-0 pl-1' : 'col-10 item-center'">
              <a :href="item.url" target="_blank" style="word-break: break-all;">{{item.name}}</a>
            </div>
            <div class="item-center p-0">
              <b-check style="float: right" v-model="item.enable"/>
            </div>
          </div>

          <!-- <input type="checkbox" class="custom-control-input" :id="index" name="example1" />
          <label class="custom-control-label" :for="index">Label</label>-->
        </div>
      </div>
    </div>
    <div class="col-12 pt-2 pb-2">
      <div class="pl-3 pt-2 pb-2 upload-document" style="border-radius: 5px; background: #EDF9F0; border: 1px solid #26BA50">
        <label class="m-0" for="file-upload" style="align-self: center; cursor: pointer;">
          <img src="../assets/icons/path.svg" >
        </label>
        <label for="file-upload" class=" custom-file-upload ml-2">
          <span class="document-name" style="word-break: break-all;">{{
            documentFileName ? documentFileName : $t("document.uploadNewDocument")
          }}</span>
          
        </label>
        <label class="m-0 pl-2 pr-2" style="align-self: center; float: right">
          <button
            v-if="documentFileName"
            type="button"  
            class="btn send-btn text-white" 
            style="padding: 0 10px; width: 55px; height: 26px;"
            @click="uploadFileToServer"
          >save</button>
        </label>
        <input v-on:change="onFileChange" id="file-upload" type="file" accept="application/pdf"/>
      </div>
    </div>
    <div class="col-12 pt-2 pb-2">
      <button
        type="button"
        class="btn send-btn text-white"
        @click="sendJobAssignment"
        style="border: 1px solid #F2404D; color: #F2404D; padding-left: 30px; padding-right: 30px;"
      >{{$t('mission.sendJobAssign')}}</button>
    </div>
    <!--  -->
    <!--  -->
    <!-- <div> -->

    <!-- </div> -->
    <snack-bar v-for="snackbar in GET_SNACK_BAR" :key="snackbar.id" :item="snackbar"></snack-bar>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import defaultPDF  from "enum/summary.js";
import VueMutiselect from "Components/VueMutiselect";
import dateUtils from "utils/dateUtils";
import axios from "axios";
import moment from 'moment';
import functionUtils from 'utils/functionUtils';
import Enum from 'enum/enum';
import TemplateCategoriesEnum from 'enum/templateCategoriesEnum'

export default {
  props:{
    summaryId: {
      type: Number,
      default() {
        return {
          id: 0
        };
      }
    },
  },
  data: () => ({
    dates: "",
    documentList: [],
    defaultPDF: defaultPDF,
    optionData: [],
    assigner: [],
    labelTextValue: "full_name",
    trackByTextValue: "full_name",
    missionData: {
      id: 0,
      summary: null,
      missionTitle: "",
      discussInfo: "",
      endTime: "",
      assigner: [],
      security: false
    },
    conclusionList: [],
    event_id: 0,
    options: {
      format: "DD/MM/YYYY",
      useCurrent: false,
      locale: "vi",
      minDate: moment().format('YYYY-MM-DD')
    },
    summaryIdValue: 0,
    // document
    documentFileName: null,
    documentFileSize: null,
    mediaServerToken: null,
    mediaServerDomain: null,
    event:{
      name: ''
    }
  }),
  components: {
    VueMutiselect
  },
  watch: {
    // GET_DOCUMENT_DATA() {
    //   this.documentList = [];
    //   this.GET_DOCUMENT_DATA.forEach(data => {
    //     let document_item = {
    //       id: data.id,
    //       name: data.name,
    //       poster_url:
    //         data.poster_url != null && data.poster_url != ""
    //           ? data.poster_url
    //           : this.defaultPDF
    //     };
    //     this.documentList.push(document_item);
    //   });
    // },
    GET_USER_IN_EVENT_LIST_DATA() {
      this.optionData = [];
      this.GET_USER_IN_EVENT_LIST_DATA.forEach(data => {
        let full_name = data.lastname + " " + data.firstname;
        let item = {
          id: data.id,
          full_name: full_name,
          url_avatar:
            data.url_avatar != null || data.url_avatar != ""
              ? data.url_avatar
              : null,
          is_group: data.is_group,
          email: data.email
        };
        this.optionData.push(item);
      });
    },
    GET_SUMMARY_LIST_DATA() {
      this.conclusionList = [];
      this.GET_SUMMARY_LIST_DATA.forEach(data => {
        let item = {
          id: data.id,
          name: data.content
        };
        this.conclusionList.push(item);
      });
      this.missionData.summary=this.summaryId;
      if (this.summaryId!=null){
        this.summaryIdValue=this.summaryId;
        let summaryData = {
          params: {
            summary_id: this.summaryId
          }
        };
        this.GET_DOCUMENT_MISSION_LIST(summaryData)
      }
    },
    GET_DOCUMENT_MISSION_LIST_DATA() {
      this.documentList = [];
      this.GET_DOCUMENT_MISSION_LIST_DATA.forEach(data => {
        let document_item = {
          id: data.id,
          name: data.name,
          poster_url:
            data.poster_url != null && data.poster_url != ""
              ? data.poster_url
              : this.defaultPDF,
          url: data.url
        };
        this.documentList.push(document_item);
      });
    },
    GET_TOKEN_MEDIA_SERVER_DATA: function() {
      this.mediaServerToken = this.GET_TOKEN_MEDIA_SERVER_DATA.data.token;
      this.mediaServerDomain = this.GET_TOKEN_MEDIA_SERVER_DATA.data.domain;
    },
    EVENT_DETAIL_DATA: function(){
      this.event.name = this.EVENT_DETAIL_DATA.name;
    }
  },
  computed: {
    ...mapGetters([
      "DRAWER_CHANGE",
      "GET_DOCUMENT_DATA",
      "GET_USER_IN_EVENT_LIST_DATA",
      "GET_SUMMARY_LIST_DATA",
      "GET_SNACK_BAR",
      "GET_DOCUMENT_MISSION_LIST_DATA",
      "GET_TOKEN_MEDIA_SERVER_DATA",
      "EVENT_DETAIL_DATA",
      "GET_CURRENT_ACCOUNT"
    ])
  },
  methods: {
    isChairmanRole: function () {
      return functionUtils.isChairmanRole(this.GET_CURRENT_ACCOUNT)
    },
    validateData: function() {
      let validatedResult = "OK";
      if (this.missionData.assigner.length == 0) {
        validatedResult =
          this.$t('common.inputFieldNull') + " " + this.$t("mission.assignPersonCompany");
      }
      else{
        for (let key in this.missionData) {
          if (this.missionData[key] === null || this.missionData[key] === "") {
            validatedResult =
              this.$t('common.inputFieldNull') + " " + this.$t("mission." + key);
            break;
          } else {
            //
          }
        }
      }

      return validatedResult;
    },
    sendJobAssignment() {
      let result = this.validateData();
      if (result == "OK") {
        let data = {
          id: this.missionData.id,
          title: this.missionData.missionTitle,
          discuss_info: this.missionData.discussInfo,
          end_time: dateUtils.formatDate(
            this.missionData.endTime,
            dateUtils.STATIC_FORMAT_DATE,
            dateUtils.STATIC_FORMAT_DATE_TIME_ZONE_T
          ),
          assigner: this.missionData.assigner,
          conclusion: this.missionData.summary,
          document: this.getDocumentSelect(),
          security: this.missionData.security
        };
        this.CREATE_MISSION(data)
          .then(
            function() {
              this.$emit('addNewMissionDone', true);
              this.sendNotification();
              let filter = {
                params: {
                  event: this.event_id
                }
              };
              this.GET_SUMMARY_LIST(filter);
              this.GET_MISSION_LIST(filter);
              let summaryFilter = {
                params: {
                  summary_id: this.summaryIdValue
                }
              };
              this.GET_DOCUMENT_MISSION_LIST(summaryFilter);
              this.ON_SHOW_TOAST({
                message: "Success",
                styleType: "success"
              });
              this.missionData = {
                id: 0,
                summary: null,
                missionTitle: "",
                discussInfo: "",
                endTime: "",
                assigner: []
              };
              
              // this.customLabel()=null
            }.bind(this)
          )
          .catch(
            function(error) {
              this.ON_SHOW_TOAST({
                message: error.response.data.message,
                styleType: "danger"
              });
            }.bind(this)
          );
      } else {
        this.ON_SHOW_TOAST({
          message: result,
          styleType: "danger"
        });
      }
    },
    getDocumentSelect: function() {
      let documentListSelect = [];
      this.documentList.forEach(data => {
        if (data.enable != undefined && data.enable == true) {
          documentListSelect.push(data.id);
        }
      });
      return documentListSelect;
    },
    getAssignerSelect(data) {
      this.missionData.assigner = [];
      data.forEach(item => {
        this.missionData.assigner.push({id: item.id, is_group: item.is_group});
      });
      // return this.assigner;
    },
    customLabel({ full_name }) {
      return `${full_name}`;
    },
    getDocumentForSummary(summary_id){
      this.summaryIdValue=summary_id;
      let summaryData = {
        params: {
          summary_id: summary_id
        }
      };
      this.GET_DOCUMENT_MISSION_LIST(summaryData)
    },
    onFileChange: function(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.documentFileName = files[0].name;
      this.documentFileSize = files[0].size;
      this.getResultFile(files[0]);
    },
    getResultFile: function(file) {
      let vm = this;
      vm.documentFile = file;
    },
    uploadFileToServer(){
      if (this.documentFileSize > 10000000) {
        this.ON_SHOW_TOAST({
          message: this.$t("document.cannotExceed10MB"),
          styleType: "danger"
        });
      } 
      else if(this.missionData.summary==null || this.missionData.summary==""){
        this.ON_SHOW_TOAST({
          message: 'Vui lòng chọn '+this.$t("mission.conclusionForMission"),
          styleType: "danger"
        });
      } else {
        let formData = new FormData();
        formData.append("file", this.documentFile);
        formData.append("directory", "documents/" + this.event_id);
        axios({
          method: "POST",
          timeout: 6000,
          url: this.mediaServerDomain + "/api/v1/upload/",
          data: formData,
          headers: { Authorization: `JWT ${this.mediaServerToken}` }
        })
          .then(
            function(res) {
              let url = res.data.domain + res.data.url
              let filter = {
                name: this.documentFileName,
                url: url,
                event: this.event_id,
                document_status: 1,
                conclusion: this.missionData.summary
              }
              this.POST_DOCUMENT_FOR_SUMMARY(filter).then(
                function () {
                  this.ON_SHOW_TOAST({
                    message: this.$t("common.success"),
                    styleType: "success"
                  });
                  this.documentFileName=null;
                  // 
                  let summaryData = {
                    params: {
                      summary_id: this.missionData.summary
                    }
                  };
                  this.GET_DOCUMENT_MISSION_LIST(summaryData)
                }.bind(this)
              ).catch(
                function () {
                  this.ON_SHOW_TOAST({
                    message: this.$t("common.somethingWentWrong"),
                    styleType: "danger"
                  });
                }.bind(this)
              )
            }.bind(this)
          )
          .catch(
            function() {
              this.ON_SHOW_TOAST({
                message: this.$t("common.somethingWentWrong"),
                styleType: "danger"
              });
            }.bind(this)
          );
      }
    },
    sendNotification(){
      let userList = []
      let groupList = []

      this.missionData.assigner.forEach(data=>{
        if (data.is_group==true){
          groupList.push(data.id)
        }else{
          userList.push(data.id)
        }
      })
      let notificationData = {
        event_id: this.event_id,
        // message_title: this.event.name,
        // message_body: this.$t('notification.mission.createMission', {'0': this.missionData.missionTitle, 
        //   '1': this.event.name, '2': this.missionData.endTime}),
        user_list: userList,
        group_list: groupList,
        action_code: TemplateCategoriesEnum.ADD_NEW_TASK,
        template_type: Enum.TemplateType.Notification,
        mission_end_time: this.missionData.endTime,
        mission_title: this.missionData.missionTitle,
        notification_type_code: Enum.NotificationType.AddNewMission
      }
      this.SEND_NOTIFICATION_IN_MEETING(notificationData)

      // let smsContent = this.$t('notification.mission.createMission', {'0': this.missionData.missionTitle, 
      //     '1': this.event.name, '2': this.missionData.endTime})
      let smsData = {
        event_id: this.event_id,
        // content: functionUtils.uniDecodeString(smsContent),
        user_list: userList,
        group_list: groupList,
        action_code: TemplateCategoriesEnum.ADD_NEW_TASK,
        event_name: this.event.name,
        template_type: Enum.TemplateType.SMS,
        mission_end_time: this.missionData.endTime,
        mission_title: this.missionData.missionTitle
      }
      this.SEND_SMS_IN_MEETING(smsData)
    },
    ...mapActions([
      "GET_USER_IN_EVENT_LIST",
      "CREATE_MISSION",
      "ON_SHOW_TOAST",
      "GET_SUMMARY_LIST",
      "GET_MISSION_LIST",
      "GET_DOCUMENT_MISSION_LIST",
      "GET_TOKEN_MEDIA_SERVER",
      "POST_DOCUMENT_FOR_SUMMARY",
      "GET_EVENT_DETAIL",
      "SEND_NOTIFICATION_IN_MEETING",
      "SEND_SMS_IN_MEETING"
    ])
  },
  mounted() {
    let event_id = sessionStorage.getItem("event_id");
    this.event_id = event_id;
    let filter = {
      params: {
        event: event_id,
        bandGroup: true
      }
    };
    this.GET_SUMMARY_LIST(filter);
    this.GET_USER_IN_EVENT_LIST(filter);
    this.GET_TOKEN_MEDIA_SERVER();
    let event = {
      params: {
        is_meeting: true
      },
      id: event_id
    }
    this.GET_EVENT_DETAIL(event)
  }
};
</script>
<style lang="scss" scoped>
@import "../style/color.scss";
.add-new-mission {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.send-btn {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
}
.my-input input {
  background-color: #000;
}
.input-group-text {
  background: white;
}
.search input {
  border-left: none;
}
.item-center {
  align-self: center;
}
.scrollbar {
  height: 180px;
  overflow-y: auto;
}
.custom-file-upload {
  display: inline-block;
  width: 100%;

  border-radius: 5px;
  cursor: pointer;
  margin: 0;
}
input[type="file"] {
  display: none;
}
.upload-document{
  background: #ffffff; 
  display: flex; 
  border-radius: 0.25rem; 
  border: 1px solid #ccc;
  word-break: break-all;
}
</style>