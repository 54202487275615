<template>
  <div class="container-fluid text-left">
    <div class="row">
      <!-- left -->
      <div class="col-sm-6 p-0" :style="'border-right: 1px solid #e5e5e5; overflow-y: auto; '">
        <!-- Add new -->
        <div
          :class="showMission == -1 ? 'select pointer p-4' : 'not-select p-4'"
          @click="clickShowMission(-1)"
          v-if="isSecretaryRole() || isChairmanRole()"
        >
          <i class="fas fa-plus ml-2" style="color:#26BA50"></i>
          <span class="p-2">{{$t('mission.addNewMission')}}</span>
        </div>
        <!-- Mission -->
        <div :style="isSecretaryRole() || isChairmanRole()
          ? 'overflow-y: auto; height:' + (screenHight-71)+'px': 'overflow-y: auto; height:' + (screenHight)+'px'">
        <div v-for="(item, index) in missionList" :key="item + index">
          <div
            :class="index==showMission ? 'select pointer' : 'not-select'"
            @click="clickShowMission(index, item)"
          >
            <div class="ml-2 mr-2 p-2" style="display: flex;">
              <!-- avatar --->
              <div
                :class="DRAWER_CHANGE ? 'col-sm-1 p-0 pl-2':''"
                style="display: inline; align-self: center;"
              >
                <div
                  v-if="item.user_create.url_avatar"
                  style="width: 30px; height: 30px; background-color: #e5e5e5; border-radius: 50%;"
                >
                  <avatar
                    :src="item.user_create.url_avatar"
                    backgroundColor="#fff"
                    color="#0097A7"
                    :size="30"
                  ></avatar>
                </div>
                <div v-else>
                  <avatar
                    :username="item.user_create.full_name"
                    :backgroundColor="index==showMission ? '#ffffff': '#e5e5e5'"
                    color="#0097A7"
                    :size="30"
                  ></avatar>
                </div>
              </div>
              <!-- content -->
              <div class="col-sm-11" style="display: inline; justify-content: space-between;">
                <!-- <div class="row"> -->
                <div>{{item.mission_content}}</div>
                <!-- </div> -->
              </div>
              <!-- icon -->
              <div class="col-sm-1'" style="display: inline;align-self: center;">
                <i class="fas fa-chevron-right float-right" style="margin:auto; color: #0097A7"></i>
              </div>
            </div>
          </div>
          <!-- Info -->
          <div v-if="index==showMission" style="border: 1px #e5e5e5 solid">
            <div class="question_content pl-4 pr-4" style="font-size: 14px">
              <table class="table table-borderless" id="table_mission_content">
                <tbody>
                  <tr>
                    <td>{{$t('mission.conclusionForMission')}}</td>
                    <td class="summary-content pointer" 
                      @click="callBackSummary(item.conclusion.id)"
                    >
                      {{ item.conclusion.content}}
                    </td>
                  </tr>
                  <tr v-if="item.security">
                    <td>{{$t('opinion.security')}}</td>
                    <td
                      style="color: #FF4081"
                    >{{ item.security ? $t('opinion.haveSecurity') : $t('opinion.noSecurity') }}</td>
                  </tr>
                  <tr>
                    <td>{{$t('mission.receiver')}}</td>
                    <td>
                      <div
                        v-for="(assigner, index) in item.assigner"
                        :key="assigner + index"
                      >{{assigner.last_name+ " " + assigner.first_name}}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>{{$t('mission.assigner')}}</td>
                    <td style="color: #FF4081">{{item.user_create.full_name}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('mission.jobStatus')}}</td>
                    <td :style="item.status.id==2 ? 'color: #F2404D' : 'color: #26BA50'">
                      {{$t(item.status.text)}}
                    </td>
                  </tr>
                  <tr>
                    <td>{{$t('mission.startTime')}}</td>
                    <td>{{item.create_time}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('mission.time')}}</td>
                    <td>{{item.end_time}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('mission.document')}}</td>
                    <td>
                      <div class="pb-2" v-for="(documentData, index) in item.document" :key="documentData+index">
                        <img
                          :src="documentData.poster_url!=null && documentData.poster_url!='' ? documentData.poster_url: defaultPDF"
                          style="width: 15px; height: 20px;"
                        />
                        <a class="pl-2" :href="documentData.url" target="_blank" style="word-break: break-all;">{{documentData.name}}</a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td v-if="item.status.id == missionStatus.RECEIVED && item.taskAssignForCurrentUser">
                      <div class="upload-document" style="border: 1px solid #26BA50">
                        <label class="m-0 p-2" for="file-upload" style="align-self: center; cursor: pointer;">
                          <img src="../../assets/icons/Plus.svg" >
                        </label>
                        <label for="file-upload" class="custom-file-upload pt-2 pb-2">
                          <span class="document-name" style="">{{
                            documentFileName ? documentFileName : $t("document.uploadNewDocument")
                          }}</span>
                        </label>
                        <label class="m-0 pt-2 pb-2 pl-2 pr-2" style="align-self: center; float: right">
                          <button
                            v-if="documentFileName"
                            type="button"  
                            class="btn save-btn text-white" 
                            style="padding: 0 10px; width: 55px; height: 26px;"
                            @click="uploadFileToServer(item, index)"
                          >{{$t('common.save')}}</button>
                        </label>
                        <input v-on:change="onFileChange" id="file-upload" type="file" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <button
                        v-if="item.status.id== missionStatus.RECEIVED && (item.taskAssignForCurrentUser|| item.user_login_in_group_assign)"
                        type="button"
                        class="btn"
                        style="border: 1px solid #26BA50; color: #26BA50; padding: 2px 20px; margin: 5px"
                        @click="showConfirmChangeStatusMission(1, item, index)"
                      >{{$t('mission.done')}}</button>
                      <button
                        v-if="item.status.id== missionStatus.RECEIVED && (isSecretaryRole() || isChairmanRole())"
                        type="button"
                        class="btn"
                        style="border: 1px solid #F2404D; color: #F2404D; padding: 2px 20px; margin: 5px"
                        @click="showConfirmChangeStatusMission(2, item, index)"
                      >{{$t('mission.pause')}}</button>
                      <button
                        v-if="(item.status.id== missionStatus.PAUSE || item.status.id== missionStatus.DONE) && (isSecretaryRole() || isChairmanRole())"
                        type="button"
                        class="btn"
                        style="border: 1px solid #26BA50; color: #26BA50; padding: 2px 20px; margin: 5px"
                        @click="showConfirmChangeStatusMission(0, item, index)"
                      >{{$t('mission.reopen')}}</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      </div>
      <!-- rigth -->
      <div class="col-sm-6 p-0">
        <div v-if="showMission!=-1 && disussMissionList.length!=0" class="chatbox" :style="'height:' + screenHight+'px'">
          <div class="chatbox-content pt-4 pl-3 pr-3" style="overflow-y: auto;">
            <div v-for="(discussData, index) in disussMissionList" :key="discussData + index">
              <div class="pb-3" v-if="discussData.user_id==discussData.user_create.id">
                <div style="display: flex">
                  <div style="display: inline; align-self: center;">
                    <div
                      v-if="discussData.user_create.url_avatar"
                      style="width: 30px; height: 30px; background-color: #e5e5e5; border-radius: 50%;"
                    >
                      <avatar
                        :src="discussData.user_create.url_avatar"
                        backgroundColor="#26BA50"
                        color="#fff"
                        :size="30"
                      ></avatar>
                    </div>
                    <avatar
                      v-else
                      :username="discussData.user_create.full_name"
                      backgroundColor="#26BA50"
                      color="#fff"
                      :size="30"
                    ></avatar>
                  </div>
                  <div
                    class="ml-3 pl-3 pr-3 pb-3 pt-1 backgroun-white"
                    style="display: inline; border-radius: 5px;"
                  >
                    <p style="color: #7D7D7D">{{discussData.user_create.full_name }}</p>
                    <div>{{discussData.content}}</div>
                  </div>
                </div>
              </div>
              <div class="pb-3" v-else style="display: flex; justify-content: flex-end">
                <div class="mr-3 p-3 backgroun-white" style="border-radius: 5px;">
                  <p>{{discussData.content}}</p>
                </div>
                <div style="display: inline; align-self: center;">
                  <div
                    v-if="discussData.user_info.url_avatar"
                    style="width: 30px; height: 30px; background-color: #e5e5e5; border-radius: 50%;"
                  >
                    <div class="circular--portrait">
                      <img
                        :src="discussData.user_info.url_avatar"
                        alt="Avatar"
                        class="circular--square"
                      />
                    </div>
                  </div>

                  <avatar
                    v-else
                    :username="discussData.user_info.full_name"
                    backgroundColor="#26BA50"
                    color="#fff"
                    :size="30"
                  ></avatar>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-1" style="bottom: 0px; width: 100%;">
            <div class="border-left-0" style="border: 1px solid #e5e5e5; background: white;">
              <div class="pt-2 pr-2" style="display: flex">
                <div style="width: 100%;">
                  <input type="text" style="width: 100%; border: none; padding: 20px;" 
                    class="meeting-font-medium"
                    v-model="disussContent"
                    v-on:keyup.enter="sendDisussMission"
                  >
                </div>
                <div style="float: right; margin-right: 10px; align-self: center; cursor: pointer;"
                  @click="sendDisussMission"
                >
                    <img src="../../assets/icons/send.svg"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <addnewmission v-if="showMission==-1 && (isSecretaryRole() || isChairmanRole())" 
          :summaryId="summaryId"
          @addNewMissionDone="addNewMissionDone"
        >
        </addnewmission>
      </div>
    </div>
    <snack-bar v-for="snackbar in GET_SNACK_BAR" :key="snackbar.id" :item="snackbar"></snack-bar>
    <confirm-modal
      ref="confirmChangeStatusMission"
      :content="confirmModalContent"
      @onConfirm="changeMissionStatus()"
    ></confirm-modal>
  </div>
</template>
<script>
import Avatar from "vue-avatar";
import AddNewMission from "Components/AddNewMission";
import { mapGetters, mapActions } from "vuex";
import dateUtils from "utils/dateUtils";
import defaultPDF from "enum/summary.js"; 
import functionUtils from 'utils/functionUtils'
import axios from "axios";
import stringUtils from 'utils/stringUtils';
import missionStatus from 'enum/missionStatus';
import ConfirmModal from "Components/ConfirmModal";
import Enum from 'enum/enum'
import TemplateCategoriesEnum from 'enum/templateCategoriesEnum'

export default {
  components: {
    avatar: Avatar,
    addnewmission: AddNewMission,
    ConfirmModal
  },
  data: () => ({
    showMission: 0,
    mission_id: 0,
    missionList: [],
    disussContent: "",
    disussMissionList: [],
    summaryId: null,
    defaultPDF: defaultPDF,
    screenHight: 0,
    missionDetailId: null,
    documentFileName: null,
    mediaServerToken: null,
    mediaServerDomain: null,
    event_id: null,
    missionStatus: missionStatus,
    confirmModalContent: '',
    changeMissionStatusData: {
      value: null,
      missionId: null,
      index: null,
      name: null,
      assigner: []
    },
    event: {
      name: ''
    }
  }),
  watch: {
    GET_MISSION_LIST_DATA() {
      this.missionList=[];
      this.GET_MISSION_LIST_DATA.forEach((data,index) => {
        let user_create_name =
          data.user_create.last_name + " " + data.user_create.first_name;
        let status = stringUtils.MissionStatus.filter(x => x.value === data.status)
            .map(x => x)[0].text
        let assign_current_user = data.assigner.findIndex(x => x.id === this.GET_CURRENT_ACCOUNT.id)
        let taskAssignForCurrentUser = false;
        if (assign_current_user != -1){
          taskAssignForCurrentUser = true
        }
        let item = {
          id: data.id,
          mission_content: data.content,
          conclusion: data.conclusion,
          create_time: dateUtils.formatDate(
            data.date_created,
            dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
            dateUtils.STATIC_FORMAT_DATE
          ),
          end_time: dateUtils.formatDate(
            data.end_time,
            dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
            dateUtils.STATIC_FORMAT_DATE
          ),
          user_create: {
            id: data.user_create.id,
            full_name: user_create_name
          },
          assigner: data.assigner,
          document: data.document,
          status: {
            'id': data.status,
            'text':status
          },
          user_login_in_group_assign: data.user_login_in_group_assign,
          taskAssignForCurrentUser: taskAssignForCurrentUser,
          security: data.security
        };
        this.missionList.push(item);
        if (this.missionDetailId != null && this.missionDetailId==data.id) {
          this.showMission=index
        }
      });
      if(this.missionDetailId != null)
        this.clickShowMission(this.showMission, this.missionList[this.showMission]);
      else{
        if((this.isSecretaryRole() || this.isChairmanRole())){
          if(this.missionList.length==0){
            this.showMission=-1
          }
          this.clickShowMission(this.showMission, this.missionList[this.showMission]);
        }
        else{
          this.clickShowMission(this.showMission, this.missionList[this.showMission]);
        }
      }
    },
    GET_DISCUSS_MISSION_LIST_DATA() {
      this.disussMissionList = [];
      this.GET_DISCUSS_MISSION_LIST_DATA.forEach(data => {
        let item = {
          id: data.id,
          content: data.content,
          user_id: data.user,
          user_create: {
            id: data.user_create.id,
            full_name:
              data.user_create.last_name + " " + data.user_create.first_name,
            url_avatar: data.user_create.url_avatar
          },
          user_info: {
            id: data.user_info.id,
            full_name:
              data.user_info.last_name + " " + data.user_info.first_name,
            url_avatar: data.user_info.url_avatar
          }
        };
        this.disussMissionList.push(item);
      });
    },
    GET_TOKEN_MEDIA_SERVER_DATA(){
      this.mediaServerToken = this.GET_TOKEN_MEDIA_SERVER_DATA.data.token;
      this.mediaServerDomain = this.GET_TOKEN_MEDIA_SERVER_DATA.data.domain;
    },
    GET_CURRENT_ACCOUNT(){
      this.missionList.forEach(data => {
        let taskAssignForCurrentUser = false;
        let checkAssigner = data.assigner.filter(x => x.id ===(this.GET_CURRENT_ACCOUNT && this.GET_CURRENT_ACCOUNT.id))
        if (checkAssigner.length > 0){
          taskAssignForCurrentUser = true;
        }
        data.taskAssignForCurrentUser = taskAssignForCurrentUser;
      })
    },
    EVENT_DETAIL_DATA(){
      this.event.name = this.EVENT_DETAIL_DATA.name;
    }
  },
  computed: {
    ...mapGetters([
      "DRAWER_CHANGE",
      "GET_MISSION_LIST_DATA",
      "GET_SNACK_BAR",
      "GET_DISCUSS_MISSION_LIST_DATA",
      "GET_CURRENT_ACCOUNT",
      "GET_TOKEN_MEDIA_SERVER_DATA",
      "EVENT_DETAIL_DATA"
    ])
  },
  methods: {
    isSecretaryRole: function () {
      return functionUtils.isSecretaryRole(this.GET_CURRENT_ACCOUNT)
    },
    isChairmanRole: function () {
      return functionUtils.isChairmanRole(this.GET_CURRENT_ACCOUNT)
    },
    clickShowMission(index, mission) {
      this.showMission = index;
      if (this.$route.query.missionDetailId!=undefined){
        this.missionDetailId=mission.id;
        this.$router.replace({
          name: "mission.title",
          query: {
            missionDetailId: mission.id
          }
        });
      }
      if (mission != undefined) {
        this.mission_id = mission.id;
        let discuss = {
          params: {
            mission_id: mission.id
          }
        };
        this.GET_DISCUSS_MISSION_LIST(discuss);
      }
    },
    sendDisussMission() {
      if (this.disussContent != null && this.disussContent != "") {
        let data = {
          mission_id: this.mission_id,
          disuss_content: this.disussContent
        };
        this.POST_DISCUSS_MISSION(data)
          .then(
            function() {
              this.disussContent="";
              let discuss = {
                params: {
                  mission_id: this.mission_id
                }
              };
              this.GET_DISCUSS_MISSION_LIST(discuss);
              this.ON_SHOW_TOAST({
                message: "Success",
                styleType: "success"
              });
            }.bind(this)
          )
          .catch(
            function(error) {
              this.ON_SHOW_TOAST({
                message: error.response.data.message,
                styleType: "danger"
              });
            }.bind(this)
          );
      }
    },
    handleResize(){
      this.screenHight=window.innerHeight-52
    },
    callBackSummary(id){
      this.$router.push({
        name: "summary.title",
        query: {
          summaryCallBackId: id
        }
      });
    },
    onFileChange: function(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.documentFileName = files[0].name;
      this.documentFileSize = files[0].size;
      this.getResultFile(files[0]);
    },
    getResultFile: function(file) {
      let vm = this;
      vm.documentFile = file;
    },
    uploadFileToServer(missionData, index){
      if (this.documentFileSize > 10000000) {
        this.ON_SHOW_TOAST({
          message: this.$t("document.cannotExceed10MB"),
          styleType: "danger"
        });
      } else {
        let formData = new FormData();
        formData.append("file", this.documentFile);
        formData.append("directory", "documents/" + this.event_id);
        axios({
          method: "POST",
          timeout: 6000,
          url: this.mediaServerDomain + "/api/v1/upload/",
          data: formData,
          headers: { Authorization: `JWT ${this.mediaServerToken}` }
        })
          .then(
            function(res) {
              let url = res.data.domain + res.data.url
              let filter = {
                name: this.documentFileName,
                url: url,
                event: this.event_id,
                document_status: 1,
                mission_id: missionData.id
              }
              this.POST_DOCUMENT_FOR_TASK(filter).then(
                function () {
                  this.ON_SHOW_TOAST({
                    message: this.$t("common.success"),
                    styleType: "success"
                  });
                  this.documentFileName=null;
                  // 
                  let filter = {
                    params: {
                      event: this.event_id
                    }
                  };
                  this.GET_MISSION_LIST(filter);
                  this.showMission=index;
                }.bind(this)
              ).catch(
                function () {
                  this.ON_SHOW_TOAST({
                    message: this.$t("common.somethingWentWrong"),
                    styleType: "danger"
                  });
                }.bind(this)
              )
            }.bind(this)
          )
          .catch(
            function() {
              this.ON_SHOW_TOAST({
                message: this.$t("common.somethingWentWrong"),
                styleType: "danger"
              });
            }.bind(this)
          );
      }
    },
    changeMissionStatus(){
      let data = {
        mission_id: this.changeMissionStatusData.missionId,
        status: this.changeMissionStatusData.value
      }
      this.CHANGE_MISSION_STATUS(data)
        .then(
          function () {
            this.ON_SHOW_TOAST({
              message: this.$t("common.success"),
              styleType: "success"
            });
            this.sendNotificationChangeStatus()
            this.sendEmail()
            let filter = {
              params: {
                event: this.event_id
              }
            };
            this.GET_MISSION_LIST(filter);
            this.showMission = this.changeMissionStatusData.index;
          }.bind(this)
        ).catch(
          function () {
            this.ON_SHOW_TOAST({
              message: this.$t("common.somethingWentWrong"),
              styleType: "danger"
            });
          }.bind(this)
        )
    },
    showConfirmChangeStatusMission(value, mission, index) {
      this.$refs.confirmChangeStatusMission.onShowModalConfirm();
      // 1, item.id, index
      this.changeMissionStatusData.value = value;
      this.changeMissionStatusData.missionId = mission.id;
      this.changeMissionStatusData.index = index;
      this.changeMissionStatusData.name = mission.mission_content;
      this.changeMissionStatusData.assigner = mission.assigner;
      if (value===0){
        this.confirmModalContent = this.$t('mission.notificationChangeStatus') + " " + this.$t('mission.received')
      }else if(value===1){
        this.confirmModalContent = this.$t('mission.notificationChangeStatus') + " " + this.$t('mission.done')
      }else if(value===2){
        this.confirmModalContent = this.$t('mission.notificationChangeStatus') + " " + this.$t('mission.pause')
      }
    },
    addNewMissionDone(){
      this.showMission=this.missionList.length;
    },
    sendNotificationChangeStatus(){
      let missionStatusName = this.changeMissionStatusData.value == this.missionStatus.RECEIVED
        ? this.$t('notification.mission.missionStatus.reopen') 
        : this.changeMissionStatusData.value == this.missionStatus.DONE 
        ? this.$t('notification.mission.missionStatus.done') 
        : this.$t('notification.mission.missionStatus.pause')
      // user assign mission
      let userList = [];
      let groupList = [];
      this.changeMissionStatusData.assigner.forEach(data => {
        if (data.is_group==true){
          groupList.push(data.id)
        }else{
          userList.push(data.id)
        }
      })
      let data = {
        event_id: this.event_id,
        // message_title: this.event.name,
        // message_body: this.$t('notification.mission.changeStatus', {'0': this.changeMissionStatusData.name,
        //   '1': missionStatusName, '2': this.GET_CURRENT_ACCOUNT.lastname + " " + this.GET_CURRENT_ACCOUNT.firstname}),
        action_code: TemplateCategoriesEnum.UPDATE_STATUS_TASK,
        template_type: Enum.TemplateType.Notification,
        mission_status: missionStatusName,
        user_change_mission_status_name: this.GET_CURRENT_ACCOUNT.lastname + " " + this.GET_CURRENT_ACCOUNT.firstname,
        mission_title: this.changeMissionStatusData.name,
        notification_type_code: Enum.NotificationType.ChangeMissionStatus
      }
      if (this.changeMissionStatusData.value != this.missionStatus.DONE ){
        data['user_list'] = userList
        data['group_list'] = groupList
      }
      else{
        data['send_chairmain_and_secretary'] = true
      }
      this.SEND_NOTIFICATION_IN_MEETING(data)

      // send sms
      // let smsContent = this.$t('notification.mission.changeStatus', {'0': this.changeMissionStatusData.name,
      //     '1': missionStatusName, '2': this.GET_CURRENT_ACCOUNT.lastname + " " + this.GET_CURRENT_ACCOUNT.firstname})

      let smsData = {
        event_id: this.event_id,
        // content: functionUtils.uniDecodeString(smsContent)
        action_code: TemplateCategoriesEnum.UPDATE_STATUS_TASK,
        template_type: Enum.TemplateType.SMS,
        mission_status: missionStatusName,
        user_change_mission_status_name: this.GET_CURRENT_ACCOUNT.lastname + " " + this.GET_CURRENT_ACCOUNT.firstname,
        mission_title: this.changeMissionStatusData.name
      }
      if (this.changeMissionStatusData.value != this.missionStatus.DONE ){
        smsData['user_list'] = userList
        smsData['group_list'] = groupList
      }
      else{
        smsData['send_chairmain_and_secretary'] = true
      }
      this.SEND_SMS_IN_MEETING(smsData)
    },
    sendEmail: function(){
      let missionStatusName = this.changeMissionStatusData.value == this.missionStatus.RECEIVED
        ? this.$t('notification.mission.missionStatus.reopen') 
        : this.changeMissionStatusData.value == this.missionStatus.DONE 
        ? this.$t('notification.mission.missionStatus.done') 
        : this.$t('notification.mission.missionStatus.pause')
      let data = {
        action_code: TemplateCategoriesEnum.UPDATE_STATUS_TASK,
        template_type: Enum.TemplateType.Email,
        event_id: this.event_id,
        mission_status: missionStatusName,
        user_change_mission_status_name: this.GET_CURRENT_ACCOUNT.lastname + " " + this.GET_CURRENT_ACCOUNT.firstname,
        mission_title: this.changeMissionStatusData.name
      }
      this.SEND_EMAIL_WITH_TEMPLATE_CONFIG(data)
    },
    // Socket
    webSocketMission: function(){
      const ws_scheme = window.location.protocol == "https:" ? "wss" : "ws"
      const backEndUrl = new URL(process.env.VUE_APP_BACKEND_URL)
      const socket = new WebSocket(
        ws_scheme +
        '://'
        + backEndUrl.host
        + `/${Enum.ChannelsActionCodeEnum.Task}/`
      )
      socket.onmessage = function (event)  {
        if (event.data != undefined){
          let message_data = JSON.parse(event.data)
          if (message_data.message == Enum.ActionEnum.Task){
            const customer_data = {
              params: {
                event: this.event_id
              }
            };
            this.GET_MISSION_LIST(customer_data);
          }
        }
      }.bind(this)

      socket.onclose = function() {
        // this.connected = false
      }
    },
    ...mapActions([
      "GET_MISSION_LIST",
      "POST_DISCUSS_MISSION",
      "ON_SHOW_TOAST",
      "GET_DISCUSS_MISSION_LIST",
      "POST_DOCUMENT_FOR_SUMMARY",
      "GET_TOKEN_MEDIA_SERVER",
      "POST_DOCUMENT_FOR_TASK",
      "CHANGE_MISSION_STATUS",
      "SEND_NOTIFICATION_IN_MEETING",
      "GET_EVENT_DETAIL",
      "SEND_SMS_IN_MEETING",
      "SEND_EMAIL_WITH_TEMPLATE_CONFIG"
    ])
  },
  mounted() {
    // summaryId
    if(this.$route.query.summaryId!=undefined){
      this.showMission=-1;
      this.summaryId=parseInt(this.$route.query.summaryId);
    }
    // missionDetailId
    if(this.$route.query.missionDetailId!=undefined){
      this.missionDetailId = parseInt(this.$route.query.missionDetailId)
    }
    let event_id = sessionStorage.getItem("event_id");
    this.event_id = event_id;
    let filter = {
      params: {
        event: event_id
      }
    };
    this.GET_MISSION_LIST(filter);
    this.GET_TOKEN_MEDIA_SERVER()
    let event = {
      params:{
        is_meeting: true
      },
      id: event_id
    }
    this.GET_EVENT_DETAIL(event);
    this.webSocketMission()
  },
  created() {
        // window.addEventListener('resize', this.handleResize);
        // this.handleResize();
        // console.log(window.innerWidth);
        // this.screenHight=window.innerHeight-50;
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    // },
    },
    // destroyed() {
    //     window.removeEventListener('resize', this.handleResize);
    // },
};
</script>
<style scoped lang="scss">
@import "../../style/color.scss";
.select {
  background: #eceff0;
}
.not-select {
  border-bottom: 1px solid #e5e5e5;
}
.pointer {
  cursor: pointer;
}
.table#table_mission_content td:nth-child(1) {
  width: 25%;
  white-space: nowrap;
}
.chatbox {
  font-size: 14px;
  /* height: 100%; */
  /* min-height: window.innerWidth;
  max-height: 690px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #eceff0;
  /* position: fixed; */
  /* width: 00%; */
}
.backgroun-white {
  background: white;
}
.circular--portrait {
  position: relative;
  max-width: 52px;
  max-height: 52px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.circular--portrait img {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.chatbox-content::-webkit-scrollbar {
    display: none; /* make scrollbar transparent */
}
.chatbox-content {
    -ms-overflow-style: none;
}
td{
  padding-top: 8px;
  padding-bottom: 8px;
}
.summary-content:hover{
  text-decoration: underline;
  color: blue;
}
.summary-content{
  color: blue;
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  display: inline-block;
  width: 100%;

  border-radius: 5px;
  cursor: pointer;
  margin: 0;
}
.upload-document{
  background: #EDF9F0; 
  display: flex; 
  border-radius: 0.25rem; 
  border: 1px solid #ccc;
  word-break: break-all;
  /* width: 60%; */
}
.document-name{
  color: #26BA50;
}
.save-btn {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
}
</style>